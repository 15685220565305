<template>
  <v-dialog v-model="state" max-width="350" persistent>
    <v-card>
      <v-card-title class="ft text-md ml-n2">Choose An Image</v-card-title>

      <v-container class="mt-n4">
        <div class="ml-1 ft text-sm mb-3">
          Select an <strong>application</strong> image to upload
        </div>
        <div class="preview-img">
          <img
            :src="
              image ? image : require('@/assets/images/placeholder-avatar.svg')
            "
            style="width: 170px;height: 170px;border-radius: 50%;"
          />
        </div>
        <div class="d-flex flex-row flex-full mt-4 ">
          <v-file-input
            :value="file"
            accept="image/*"
            class="ft text-sm"
            label="App Logo"
            show-size
            @change="handleSelect($event)"
          />
        </div>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          class="text-capitalize text-sm ft"
          color="blue accent-3"
          text
          @click="close"
        >
          Cancel
        </v-btn>

        <v-btn
          class="text-capitalize text-sm ft"
          color="blue accent-3"
          text
          @click="action({ state: false, idx: 'image' })"
        >
          Select
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ImageUpload",
  props: {
    state: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    image: "",
    file: []
  }),
  methods: {
    close() {
      this.image = "";
      this.file = [];
      let payload = {
        idx: "image",
        state: false
      };
      this.$emit("actions", payload);
    },
    action(state) {
      let payload = Object.assign(state, { logo: this.image });
      this.$emit("close", payload);
    },
    handleSelect($event) {
      if ($event !== null || undefined) {
        let reader = new FileReader();
        reader.onload = ev => {
          this.image = ev.target.result;
        };
        reader.readAsDataURL($event);
      } else {
        this.image = "";
        this.file = [];
      }
    }
  }
};
</script>

<style scoped></style>
